import React from "react";

function rot (d: any) {
   return {
      transform: `rotate(${d}deg)` };
}
function rotImg (imgSrc: any, d: any) {
   return {
      display: 'inline-block',
      background: `url(${imgSrc}) no-repeat scroll center center`,
      backgroundSize: "cover",
      WebkitBackfaceVisibility: "hidden" as any,
      backfaceVisibility: "hidden" as any,
      transform: `translateZ(1px) rotate(${d}deg)`,
      outline: "1px solid transparent",
   };
}

export default class AboutMe extends React.Component<{}, {imagesLoaded: number}> {
    imageSpecs: any;

    constructor(props: any) {
        super(props);
        this.state = { imagesLoaded: 0 };
        let imageRoot = "/media/";
        this.imageSpecs = [
           { src: imageRoot + "Marie.jpg", d: -17 },
           { src: imageRoot + "Walter.jpg", d: -10 },
           { src: imageRoot + "Edward2.jpg", d: -4 },
           { src: imageRoot + "Harry.jpg", d: 3 },
           { src: imageRoot + "Bernard.jpg", d: 10 }];

        this.imageSpecs.map ((item:any) => {
           var image = new Image ();
           image.onload = () => this.imageLoaded ();
           image.src = item.src;
        });
        
    }

    imageLoaded() {
        this.setState({ imagesLoaded: this.state.imagesLoaded + 1 });
    }

    render() {
        return <div className='section section-alt' id="about">
            <div className='row'>
                <h1>About Me</h1>
                <div style={{transition: "opacity .5s", textAlign:"center", opacity: this.state.imagesLoaded >= 4 ? 1 : 0}}>
                    <div style={{ borderRadius: 10, display: "inline-block", overflow: "" }}>
                    {
                       this.imageSpecs.map ((image:any) =>
                          <div className='pic-v' style={rotImg(image.src, image.d)} />
                          //<img src={image.src} className='pic-v' style={rot(image.d)} />
                       )
                    }
                        </div>
                </div>
                <div style={{transition: "opacity .5s", maxWidth: 620, margin: "0 auto", opacity: this.state.imagesLoaded >= this.imageSpecs.length ? 1 : 0}}>
                    <h2>
                        I love a good challenge.<br/><br/>
                        <small>Distance running.  Piano.  Chess.  Puzzles.  Back country camping.  Skiing moguls.  Building software.
                        <br /><br/>
                        I continue to discover and revel in new adventures.  My lovely lady and I
                        met in the UK and were wed in Italy, and I am more proud than anything to be a part of
                        this great family we're building together.
                        <br/><br/>
                        Really, we're just getting started ... the best is yet to come.
                        <br />
                    </small>
                    </h2>
                </div>
            </div>
        </div>;
    }
}
