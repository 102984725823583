import React from "react";

const Ruffle = (props: {flip?: boolean}) =>
    <div className='section' style={{background:"none"}}>
    <div
        style={{
            width: "100%",
            height: 20,
            backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAUCAYAAAGIQU8tAAAAB3RJTUUH4wQVBzYscMgp8gAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAARnQU1BAACxjwv8YQUAAAD9SURBVHjazZTbCsIwDIb7LCLKGHjvAeY8oO//CnrnO2xSoSNLmyZpo3jxw5YlP1/WNG67716L5WqEcqfLfdwdjo8Q8O+fYPgQnh0uncphYFYeAlMwRUBWYzLoRgJhrZs2hkwlReBYTbuZN5NKhklRYkjGSeLfI22Y/Y0Ssa1IjVPtkoY5Y8pIZAiNOSPVhEnV9deBnUSNGTuxWrPsZEvVn29D9lBqzMhTLjXLjk2JGTuHWjPRYGvMxDdFama6vizlV2F0Uf4BFIJlj+TXoH4xYrDidW0NVn1LvgEqAVMBWoFqwIoAS0FLwKoApaA1YCaAFKgFmCkgAH1agQW9AfEnDI5ZbTDCAAAAAElFTkSuQmCC')",
            backgroundRepeat: "repeat-x",
            transform: props.flip ? "scaleY(-1) translate(0,1px)" : undefined
        }}>
        </div>
        </div>;


export default Ruffle;