import React from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import ReactDOM from "react-dom";
import { setTimeout, clearInterval } from "timers";

var MenuItem = (props: any) => {
    var { children, tag, selected, ...other } = props;
    return <a {...other} className={`menuitem menuitem-${tag == selected ? "selected" : ""}`}>{children}</a>; 
};

type Props = RouteComponentProps<{ section: string }>;

function scrollTo(elem: any, offset?: number) {
    var element = document.getElementById(elem);
    element && window.scrollTo(0,element.offsetTop - (offset||0));
}

class Menu extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.scrollHandler = (e: any) => {
            var scrollTop = (window.scrollY||window.pageYOffset||0);

            var scrollBottom = scrollTop + window.innerHeight;

            for (var i = this.sections.length - 1; i >= 0; --i) {
                var section = this.sections[i];
                var elem = document.getElementById(section);
                if (!elem)
                    continue;

                var middle = elem.offsetTop;
                if (elem.clientHeight >= window.innerHeight)
                    middle += window.innerHeight / 2;
                else
                    middle += elem.clientHeight / 2;

                if (middle > scrollTop && middle < scrollBottom) {
                    if (section != this.props.match.params.section)
                        this.props.history.replace(`/${section}`);
                    return;
                }
            }
        };
        this.sections = ["about", "professional", "contact"];
    }

    scrollHandler: any;
    scrollHandlerHandle?: any;
    sections: string[];
    myElement?: any;

    render() {
        var { section }= this.props.match.params;

        return <div ref={e => this.myElement = e} className='section section-menu sticky-0'>
            <MenuItem tag="about" selected={section} onClick={() => { scrollTo("about", 20); }}>About Me</MenuItem>
            <MenuItem tag="professional" selected={section} onClick={() => { scrollTo("professional", 20); }} >Professional</MenuItem>
            <MenuItem tag="contact" selected={section} onClick={() => { scrollTo("contact", 20); }}>Contact</MenuItem>
        </div>;
    }

    componentDidMount() {
        var { section } = this.props.match.params;
        var myElement = ReactDOM.findDOMNode(this) as HTMLDivElement;
        var offset = 20 + (myElement ? myElement.clientHeight + myElement.offsetTop : 0);
        scrollTo(section, section == this.sections[0] ? offset : 20);

        this.scrollHandlerHandle = setInterval(this.scrollHandler, 300);
    }

    componentWillUnmount() {
        clearInterval(this.scrollHandlerHandle);
    }
}

export default withRouter<Props>(Menu as unknown as any) as unknown as typeof Menu;