import React from "react";
import { Link } from "react-router-dom";
import Infographic from './Infographic';


export default class Professional extends React.Component<{}, {}> {
    render() {
        return <div className='section section-alt2' style={{minHeight: 600}} id="professional">
            <div className='col-12'>
                <div className='row' style={{textAlign:"center"}}>
                    <h1>Professional Info</h1>
                    <h2 style={{maxWidth: 620, margin: "0 auto" }}>
                        I am an enthusiastic software engineer and accomplished project manager.<br /><br />
                        <Infographic img="/media/coder.png" title="Software Development" />
                        <Infographic img="/media/gantt.png" title="Project Management" />
                        <Infographic img="/media/risk.png" title="Risk & Opportunity Management" />
                        <br/>
                        <br/>
                        <small>Helping organizations leverage technology in innovative ways
                            to improve their processes and be more efficient is my passion.</small>
                        <br /><br/>
                        <small>With 16 years in multi-domain project management and devops,
                            I've developed a customer-first mentality that has led to successful,
                            rewarding endeavors.  I look forward to so many more to come.</small>
                        <br /><br/>
                        <small>Take a look at my LinkedIn profile or check out my resume for more details.</small>
                    </h2>
                    <br/>
                    <h2><a target='_blank' rel="noopener" href={`https://www.linkedin.com/in/sean-martin-9647b044/`}><img src='media/LinkedIn.png' /></a></h2>
                    <div className='printonly'>https://www.linkedin.com/in/sean-martin-9647b044/</div>
                    <br/>
                    <h2><a target='_blank' rel="noopener" style={{ padding: 10, textDecoration: "none", background: "white", color: "#424448", borderRadius: 3 }} href={`/media/Resume%20-%20Sean%20Martin.pdf`}>Resume</a></h2>
                    <div className='printonly'>/media/Resume%20-%20Sean%20Martin.pdf</div>
                    <br/>
                </div>
            </div>
        </div>;
    }
}
