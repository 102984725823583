import React from "react";

const Infographic = (props: { img: string, title?: string }) =>
    <div className="infographic"
        title={props.title}
        style={{
            backgroundImage: "url(" + props.img + ")",
        }} />;


export default Infographic;