import React from "react";



export default class Shot extends React.Component<{}, {shotLoaded?: boolean, squiggleLoaded?: boolean}> {
    constructor(props:any) {
        super(props);
        this.state = { squiggleLoaded:true};
    }


    render() {
        var imgStyle = { opacity: this.state.shotLoaded && this.state.squiggleLoaded ? undefined : 0 };

        return <div className='section' style={{position:"relative"}}>
            <div className='col-6 col-6-sm'>
                <div className='flexy topsection'>
                    <img style={imgStyle} onLoad={() => this.setState({ shotLoaded: true })}
                        className='shot' src='/media/Sean.jpg' />
                </div>
            </div>
            <img style={imgStyle} src='/media/hiimsean.png' className='stickynote' />
        </div>;
    }
}
