import React, { Fragment } from "react";
import { fetch, addTask } from "domain-task";
import { PostJson } from "../Tools";


export default class Contact extends React.Component<{}, {content: string, requested: boolean, submitted: boolean}> {
    constructor(props: any) {
        super(props);
        this.state = { content: "", submitted: false, requested: false };
    }
    editor: any;

    render() {
        var canSubmit = this.editor && (this.editor.value || "") != "" && !this.state.requested;

        return <div className='section section-alt' id="contact">
            <div className='col-12'>
                <div className='row' style={{ height: 700, }}>
                    <h1 style={{textAlign:"center"}}>Contact Me</h1>
                    <h2 style={{ maxWidth: 600, margin: "0 auto" }}>
                        Drop me a line and I'll be in touch.
                        </h2>
                    <br/>
                    {
                        this.state.submitted ?
                            <h2>Thanks for reaching out. :)</h2>
                            :
                            <Fragment>
                                <iframe title="contactdummyframe" name="contactdummyframe" id="contactdummyframe"
                                    style={{ width: 0, height: 0, border: "none", margin: 0, padding: 0 }}
                                    onLoad={(e) => {
                                        if (e.currentTarget.contentDocument) {
                                            if (e.currentTarget.contentDocument.baseURI.indexOf ("/api/contacts") >= 0)
                                            this.setState({ submitted: true });
                                        }
                                    }} ></iframe>
                                <form autoComplete="done"
                                    onSubmit={() => { this.setState({ requested: true }); }}
                                    onInvalid={() => { this.setState({requested:false}); }}
                                    action="/api/contacts" method="POST" target="contactdummyframe" style={{ maxWidth: 600, margin: "0 auto" }}>
                                    <input autoComplete="done" required className='contactinput' placeholder="your email" type='email' name='identification' />
                                    <input className='triangular' type='text' name='email' />
                                    <br/>
                                    <br/>
                                    <input ref={e => { this.editor = e; }} hidden type='text' name='message' />
                                    <div placeholder="a quick message"
                                        contentEditable={true}
                                        onInput={(e) => {
                                            this.setState({});
                                            this.editor.value = e.currentTarget.innerHTML; } } className='contactinput' />
                                    <input type='submit' value='Submit'
                                        disabled={!canSubmit} className='contactbutton' />
                                </form>
                        </Fragment>
                    }
                </div>
            </div>
        </div>;
    }
}