import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Shot from './components/Shot';
import AboutMe from './components/AboutMe';
import Menu from './components/Menu';
import { Router, Route, Switch } from "react-router";
import { BrowserRouter } from 'react-router-dom';
import Professional from './components/Professional';
import Contact from './components/Contact';
import Ruffle from './components/Ruffle';

class App extends Component {
  render() {
      return (
          <div className="App">
            <BrowserRouter>
                <Shot />
                <Switch>
                    <Route path="/:section" component={Menu} />
                    <Route path="/" component={Menu} />
                </Switch>
                  <AboutMe />
                  <Ruffle />
                  <Professional />
                  <Ruffle flip={true} />
                  <Contact />
                  <div className='copyright'>&copy; {new Date(Date.now()).getFullYear()} Sean Martin</div>
            </BrowserRouter>
      </div>
    );
  }
}

export default App;
